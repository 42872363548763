:root {
  --toastify-color-info: #3983b4;
  --toastify-color-success: #1aad1f;
  --toastify-color-warning: #e1b916;
  --toastify-color-error: #cb2b19;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
}


@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto-Condensed';
src: url('assets/fonts/RobotoCondensed-Regular.ttf');
}

@font-face {
  font-family: 'Merriweather';
  src: url('assets/fonts/Merriweather-Regular.ttf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
